import React, {MouseEventHandler, ReactNode} from "react";
import {Button, CircularProgress, Dialog, DialogContent, DialogTitle, Typography} from "@barracuda-internal/bds-core";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {
    Account,
    Authenticated,
    authLogout,
    ChangeAccountButton,
    ConditionalWrapper,
    Toolbar,
    useMixpanel
} from "@cuda-react/core";
import {get} from "lodash";
import {Theme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {CudaTheme} from "@cuda-react/theme";
import apiResources from "../../mainApiResources";

export const styles = (theme: Theme & typeof CudaTheme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.palette.background.paper,
        backgroundImage: "url('https://auth.barracudanetworks.com/img/bg_product-login.jpg')",
        backgroundSize: "cover",
        color: theme.palette.primary.contrastText,
        paddingLeft: "calc(50% - 350px)",
        paddingRight: "calc(50% - 350px)"
    },
    title: {
        textAlign: "center"
    },
    messageCenter: {
        textAlign: "center",
        paddingBottom: 8
    },
    changeAccount: {
        width: 256
    },
    toolbar: {
        marginTop: theme.spacing(2)
    },
    dialogRoot: {
        "& > div:first-child": {
            opacity: "0 !important"
        }
    }
});

const useStyles = makeStyles(styles);

interface WholePageLayoutProps {
    additionalButton?: ReactNode,
    title: string,
    message?: string | ReactNode,
    loading?: boolean,
    list?: string | ReactNode,
    logout?: boolean,
    onTrialClick?: MouseEventHandler<HTMLButtonElement>,
    selectAccount?: boolean,
    onAccountChange?: (account: Account) => void,
    onContinue?: () => void,
    onCancel?: () => void,
    demo?: boolean,
    subscribe?: boolean,
    noAuth?: boolean,
    errorMessage?: string
}

export const WholePageLayout = (props: WholePageLayoutProps) => {
    const registerAction = useMixpanel("Welcome", false);
    const {additionalButton, errorMessage, title, message, loading, list, logout, selectAccount, onAccountChange, onContinue, demo, subscribe, onTrialClick, onCancel, noAuth} = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();
    const dispatch = useDispatch();
    const showToolbar = logout || selectAccount || !!onContinue || demo || subscribe || !!onCancel || !!additionalButton || !!onTrialClick;

    // @ts-ignore
    return (
        <ConditionalWrapper
            condition={!noAuth}
            wrapper={(children: ReactNode) => (
                <Authenticated authParams={(globalParams: any) => !!get(globalParams, "userData")}>
                    {children}
                </Authenticated>
            )}
        >
            <div className={classes.root} id="subscription-page">
                <Dialog open classes={{root: classes.dialogRoot}}>
                    <DialogTitle className={classes.title}>
                        <Typography variant="h5">
                            {translate(title)}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        {loading && <CircularProgress/>}
                        {message && (
                            <Typography variant="body2" className={classes.messageCenter}>
                                {typeof message === "string" ? translate(message) : message}
                            </Typography>
                        )}
                        {list && (
                            <Typography variant="body2">
                                {list}
                            </Typography>
                        )}
                        {showToolbar && (
                            <Toolbar center classes={{root: classes.toolbar}}>
                                <React.Fragment>
                                    {demo ? (
                                        <Button
                                            href="https://cloudgenwan-demo.barracudanetworks.com"
                                            variant="contained"
                                            color="secondary"
                                            size="small"
                                            onClick={() => {
                                                registerAction("View Demo", {referrer: document.referrer});
                                            }}
                                        >
                                            {translate("stratos.subscription.demo")}
                                        </Button>
                                    ) : null}
                                    {onContinue ? (
                                        <Button
                                            onClick={onContinue}
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                        >
                                            {translate("stratos.subscription.continue")}
                                        </Button>
                                    ) : null}
                                    {logout ? (
                                        <Button
                                            onClick={() => dispatch(authLogout())}
                                            variant="contained"
                                            color="secondary"
                                            size="small"
                                        >
                                            {translate("cuda.auth.logout")}
                                        </Button>
                                    ) : null}
                                    {onTrialClick ? (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            onClick={onTrialClick}
                                        >
                                            {translate("stratos.welcome.trial")}
                                        </Button>
                                    ) : null}
                                    {subscribe ? (
                                        <Button
                                            href="https://portal.azure.com/#create/barracudanetworks.barracuda_cloudgenwan/preview"
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            onClick={() => {
                                                registerAction("Subscribe", {referrer: document.referrer});
                                            }}
                                        >
                                            {translate("stratos.subscription.subscribe")}
                                        </Button>
                                    ) : null}
                                    {additionalButton}
                                    {onCancel ? (
                                        <Button
                                            onClick={onCancel}
                                            variant="contained"
                                            color="secondary"
                                            size="small"
                                        >
                                            {translate("stratos.subscription.cancel")}
                                        </Button>
                                    ) : null}
                                    {selectAccount ? (
                                        <ChangeAccountButton
                                            userDataResource={apiResources.accountUser}
                                            onAccountChange={(account: Account) => {
                                                registerAction("Swap Account", {referrer: document.referrer});
                                                onAccountChange?.(account);
                                            }}
                                            selectProps={{
                                                classes: {formControl: classes.changeAccount}
                                            }}
                                        />
                                    ) : null}
                                </React.Fragment>
                            </Toolbar>
                        )}
                        {errorMessage  && (
                            <Typography variant="caption" color="error" className={classes.messageCenter} component="div">
                                {errorMessage}
                            </Typography>
                        )}
                    </DialogContent>
                </Dialog>
            </div>
        </ConditionalWrapper>
    );
};

export default WholePageLayout;